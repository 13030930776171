import * as Sentry from '@sentry/nextjs';
import { NavigatorsHeader } from '@/types/Content/NavHeader';
import { Navigator, NavigatorV2 } from '@/types/Content/Navigators';
import { cmsProxyApi } from './cmsProxyApi';
import { api } from './api';

interface HTTPHeaderResponse {
  data: NavigatorsHeader;
}
interface HTTPResponse {
  data: {
    result: Navigator[];
    next: boolean;
    previous: boolean;
    total?: number;
  };
}

interface HTTPNavigatorsV2Response {
  data: NavigatorV2[];
}

const navigatorsService = {
  async getNavHeaders(): Promise<HTTPHeaderResponse['data'] | null> {
    try {
      const { data }: HTTPHeaderResponse = await cmsProxyApi.get(
        'navigators/header',
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },
  async getNavigators({
    page = 0,
    perPage = 4,
    homePageOnly = false,
  } = {}): Promise<HTTPResponse['data'] | null> {
    try {
      const params: any = { page, perPage, includeTotalCount: true };
      if (homePageOnly) {
        params.homePageOnly = true;
      }
      const { data }: HTTPResponse = await cmsProxyApi.get('navigators', {
        params,
      });
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },
  async getNavigatorsV2(): Promise<HTTPNavigatorsV2Response['data'] | null> {
    try {
      const { data }: HTTPNavigatorsV2Response = await api.get(
        `concierge/navigators`,
      );
      console.log('getNavigatorsV2 | data', JSON.stringify(data));
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
};
export default navigatorsService;
