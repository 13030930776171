import { GroupSession } from '../Village/GroupSession';
import { DbManagerUser } from './User';

export enum EOrgRole {
  Owner = 'Owner',
  Admin = 'Admin',
  Employee = 'Employee',
}
export type OrgRole = {
  role: EOrgRole;
};

export type Organization = OrgRole & {
  id: string;
  name: string;
  image_url: string;
  max_employees: 0;
  employee_count: 0;
  admin_access?: ('employee' | 'statistics')[];
  onboarding_finished: boolean;
  copay_info?: null | { max_sessions: number; used_sessions: number };
  ai_enabled?: boolean;
  use_only_company_concierge?: boolean;
  use_only_company_navigator?: boolean;
};

export type OrgUser = OrgRole & {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
};

export type OrgUsersData = {
  items: OrgUser[];
  has_next: boolean;
  has_previous: boolean;
  total: number;
};

export type InviteData = Pick<OrgUser, 'first_name' | 'last_name' | 'email'>;

export type OrgStats = {
  total_employees: number;
  total_concierge_sessions: number;
  total_navigators_sessions: number;
  total_coach_sessions: number;
  total_family_members: number;
  total_topics: Record<string, number>;
};

export type OrgFeedback = {
  id?: string;
  title?: string;
  rating: number;
  comment: string;
  created_at: string;
};
export type OrgFeedbackData = {
  items: OrgFeedback[];
  total: number;
  has_next: boolean;
  has_previous: boolean;
};

export enum OrgStatsPeriod {
  month = 'month',
  year = 'year',
  // week = 'week',
}

export type OrgStatsInfo = Record<string, OrgStats>;

export type PromoteToTypes =
  | 'promote-to-admin'
  | 'demote-from-admin'
  | 'promote-to-owner';

export type SessionItem = {
  title: string;
  slug: string;
  image: string;
  description: any[];
  short_description: any[];
  exact_date_time: string;
  month_date_time: string;
  session_length: number;
  sponsor: {
    name: string;
    url: string;
    logo: string;
  };
  address: string;
  prize: any[];
  call_link?: string;
  coaches: Array<{
    slug: string;
    fullName: string;
    subTitle: string;
    image: string;
    specialties: string[];
  }>;
};
export type SessionsData = {
  total: number;
  next: boolean;
  previous: boolean;
  result: SessionItem[];
};
export type GroupSessionsData = {
  total: number;
  next: boolean;
  previous: boolean;
  result: GroupSession[];
};

export type AccManager = DbManagerUser;

export type CheckListDoc = {
  id: string;
  title: string;
  fileKey?: OrgFileType;
  description?: string;
  date?: string;
  url?: string;
};

export type OrgInvoice = {
  id: string;
  date: string;
  invoice_url: string;
};

export type OrgInvoicesData = {
  items: OrgInvoice[];
  has_next: boolean;
  has_previous: boolean;
  total: number;
};

export type OrgNextInvoiceData = {
  date: string;
};

export type StartUploadData = {
  file_name: string;
  file_type: string;
};
export type StartUploadResponse = {
  file_id: string;
  url: string;
  fields: {
    acl: string;
    'Content-Type': string;
    key: string;
    AWSAccessKeyId: string;
    'x-amz-security-token': string;
    policy: string;
    signature: string;
  };
};

export type FinishUploadResponse = {
  id: string;
  file_name: string;
  file_type: string;
  original_file_name: string;
  uploaded_at: string;
  file_url: string;
};

export type OrgFile = {
  id: string;
  file_url: string;
};

export type OrgMainFiles = {
  plan_file: OrgFile;
  team_mailing_list_file: OrgFile;
};

export type OrgFileType = 'plan' | 'team_mailing_list' | 'other' | 'image';
export type OrgFileUrl = 'plan' | 'team-mailing-list' | 'other';

export type OrgOtherFile = OrgFile & {
  uploaded_at?: string;
  original_file_name?: string;
};
export type OrgOtherFilesData = {
  items: OrgOtherFile[];
  total: 0;
  has_next: false;
  has_previous: false;
};
